<template>
  <v-container fluid>
    <v-overlay
      :value="loading"
    >
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-row>
      <v-col
        cols="12"
        md="6"
        sm="12"
      >
        <v-card>
          <v-card-title>
            Importer un fichier de N° de suivi
          </v-card-title>
          <v-card-text>
            <v-form @submit.prevent="uploadAndImport()">
              <v-row dense>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-alert
                    border="left"
                    type="info"
                    colored-border
                    elevation="2"
                    class="mb-12"
                  >
                    L'import a besoin des informations suivantes afin de fonctionner :
                    <ul>
                      <li>La référénce de la commande </li>
                      <li>Le N° de suivi</li>
                      <li>Facultatif : le code du transporteur si celui-ci doit être changé</li>
                    </ul>
                  </v-alert>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col
                  cols="12"
                  md="6"
                  sm="12"
                  class="py-0"
                >
                  <StoreSelector
                    v-model="tracking.import.storeId"
                    filter
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  sm="12"
                  class="py-0"
                >
                  <v-file-input
                    v-model="tracking.import.file"
                    outlined
                    hide-details
                    dense
                    prepend-inner-icon="mdi-file-delimited-outline"
                    :prepend-icon="null"
                    accept="text/csv"
                    label="Sélectionner le fichier à importer"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col
                  cols="12"
                  md="12"
                  class="py-0"
                >
                  <v-checkbox
                    v-model="importUnlocked"
                    label="Êtes-vous sûr de vouloir importer ce fichier ?"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-col
              md="6"
              align="right"
            >
              <v-btn
                :disabled="importUnlocked === false || tracking.import.file === null"
                color="teal accent-4"
                left
                outlined
                @click="uploadAndImport()"
              >
                <v-icon left>
                  mdi-check-outline
                </v-icon>Importer
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <Snackbar />
  </v-container>
</template>
<script>

import Snackbar from '@/components/core/Snackbar.vue'
import snackbarMixin from '@/mixins/snackbar'
import StoreSelector from '@/components/base/StoreSelector.vue'

export default {
  name: 'TrackingImportModal',
  components: { Snackbar, StoreSelector },
  mixins: [snackbarMixin],
  data() {
    return {
      loading: false,
      importUnlocked: false,
      tracking: {
        import: {
          storeId: null,
          file: null,
        },
      },
      rules: {
        required: value => !!value || this.$i18n.t('forms.fields.required'),
      },
    }
  },
  methods: {
    async uploadAndImport() {
      if (this.tracking.import.file === null || this.tracking.import.storeId === null) {
        return
      }
      
      this.loading = true
      
      const formData = new FormData()
      formData.append('file', this.tracking.import.file)
      formData.append('storeId', this.tracking.import.storeId)
      
      try {
        const response = await this.$axios.post(
          'v3/shipments/import/tracking',
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )
        this.snackbarSuccess(
          this.$i18n.t('views.shipment.forms.messages.tracking_import.success', {
            created: response.data.created,
            updated: response.data.updated,
            ignored: response.data.ignored,
          })
        )
      } catch (error) {
        this.snackbarError(
          this.$i18n.t('views.shipment.forms.messages.tracking_import.failed', {
            error: error?.response?.data['hydra:description'] ?? 'internal error' }
          )
        )
      } finally {
        this.tracking.import.file = null
        this.tracking.import.storeId = null
        this.loading = false
        this.importUnlocked = false
      }
    },
  },
}
</script>
